import React, { useContext } from "react"
import { Link, useStaticQuery } from "gatsby"
import { FaMapMarkerAlt, FaPhoneAlt, FaCalendarWeek } from "react-icons/fa"

import { GatsbyContext } from "../../context/context"
import hours from "../../constants/hours"

import socials from "../../constants/socials"

const FooterTop = () => {
  const { links } = useContext(GatsbyContext)
  // console.log(nodes)

  return (
    <section className="footer-top u-margin-top-big">
      <div className="row">
        <div className="col-3-of-3">
          <article className="footer-top__article">
            <h3 className="heading-ordinary heading-ordinary--primary u-margin-bottom-small">
              Links
            </h3>

            <nav className="footer-top__navigation">
              <ul className="footer-top__navigation--list">
                {links.map(link => {
                  return (
                    <li key={link.id}>
                      <Link
                        to={link.url}
                        className="paragraph paragraph--white"
                      >
                        {link.text}
                      </Link>
                    </li>
                  )
                })}
              </ul>
            </nav>
          </article>
          <article className="footer-top__article">
            <div className="footer-top__reach-us">
              <h3 className="heading-ordinary heading-ordinary--primary u-margin-bottom-small">
                Contact
              </h3>
            </div>

            <nav className="footer__socials">
              <ul>
                {socials &&
                  socials.map(item => {
                    return (
                      <li key={item.id}>
                        <a
                          href={item.url}
                          target="_blank"
                          className="footer-top__text"
                        >
                          {item.icon}
                        </a>
                      </li>
                    )
                  })}
              </ul>
            </nav>

            <p className="footer-top__text">
              <p className="paragraph paragraph--white">
                <FaMapMarkerAlt className="footer-top__icon" /> 54 Main St,
                Montpelier, VT 05602
              </p>
            </p>
            <p className="paragraph paragraph--white">
              <FaPhoneAlt className="footer-top__icon" />{" "}
              <a href="tel:802-225-6010" className="phone-link">
                802-225-6010
              </a>
            </p>
          </article>

          <article className="footer-top__article">
            <h3 className="heading-ordinary heading-ordinary--primary u-margin-bottom-small">
              Hours
            </h3>

            <p className="paragraph paragraph--white">
              <p className="paragraph paragraph--white footer-top__hours">
                <FaCalendarWeek className="footer-top__icon" />{" "}
                <ul>
                  {hours &&
                    hours.map(hour => {
                      return (
                        <li key={hour.id}>
                          <p>
                            {hour.day} {hour.hour}
                          </p>
                        </li>
                      )
                    })}
                </ul>
              </p>
            </p>
          </article>
        </div>
      </div>
    </section>
  )
}

export default FooterTop
