import React from "react"
import Title from "./Title"

const AboutUs = () => {
  return (
    <section id="about-us" className="u-margin-top-big u-margin-bottom-big">
      <div className="u-header-wrap u-margin-top-big u-margin-bottom-big">
        <div className="row u-page-header">
          <div className="col-1-of-1">
            <Title
              title="the restaurant"
              className="heading-title heading-title--grey"
            />
            <p className="heading-tertiary heading-tertiary--grey">
              A little about us and a breif history of how we started.
            </p>
          </div>
        </div>
      </div>

      <section className="section u-margin-top-big">
        <div className="row">
          <div className="col-1-of-1">
            <article>
              <p className="paragraph">
                The Pho Thai Express restaurant was founded by husband and wife,
                Sam and Nok, in 2015. Sam and Nok came to the United States from
                Thailand more than 40 years ago. They are passionate about Thai
                cuisine which motivates them to share their Thai food with
                others in the United States.
                <br />
                <br />
                They lived and owned a successful Thai restaurant in Los
                Angeles. They traveled to Vermont and fell in love with Green
                Mountain State, which is why they made the decision to live here
                and they see Montpelier as a place of opportunity to share
                authentic Thai food, which is how the Pho Thai Express
                restaurant was born.
                <br />
                <br />
                You get fresh authentic Thai food every day here at the Pho Thai
                Express restaurant in Montpelier, Vermont.
              </p>
            </article>
          </div>
        </div>
      </section>
    </section>
  )
}

export default AboutUs
