import React from "react"
import { FaMapMarkerAlt, FaPhoneAlt, FaCalendarWeek } from "react-icons/fa"

import Title from "./Title"
import hours from "../constants/hours"
import Map from "./map"

const Contact = () => {
  return (
    <section id="contact" className="u-margin-top-big u-margin-bottom-big">
      <div className="u-header-wrap u-margin-top-big u-margin-bottom-big">
        <div className="row u-page-header">
          <div className="col-1-of-1">
            <Title
              title="contact"
              className="heading-title heading-title--grey"
            />
            <p className="heading-tertiary heading-tertiary--grey">
              Contact our restaurant
            </p>
          </div>
        </div>
      </div>

      <section className="section u-margin-top-big">
        <div className="row u-margin-top-huge">
          <div className="col-2-medium-of-2">
            <article className="contact__article">
              <p className="contact__text">
                <h3 className="heading__main-title heading__main-title--sub heading__main-title--sub--dark u-margin-bottom-small">
                  Address
                </h3>
                <p className="paragraph paragraph--dark">
                  <FaMapMarkerAlt className="contact__icon" /> 54 Main St,
                  Montpelier, VT 05602
                  <br />
                  <FaPhoneAlt className="contact__icon" /> 802-225-6010
                </p>

                <h3 className="heading__main-title heading__main-title--sub heading__main-title--sub--dark u-margin-bottom-small">
                  Working hours
                </h3>
                <p className="paragraph paragraph--dark contact__hours">
                  <FaCalendarWeek className="contact__icon" />
                  <ul>
                    {hours &&
                      hours.map(hour => {
                        return (
                          <li key={hour.id}>
                            <p>
                              {hour.day} {hour.hour}
                            </p>
                          </li>
                        )
                      })}
                  </ul>
                </p>
              </p>
            </article>

            <article>
              <Map width="600" height="450" />
            </article>
          </div>
        </div>
      </section>
    </section>
  )
}

export default Contact
