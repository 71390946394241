import React, { useContext } from "react"

import { FaAlignRight } from "react-icons/fa"
import { Link, useStaticQuery, graphql } from "gatsby"
import { GatsbyContext } from "../context/context"
import Image from "gatsby-image"

const query = graphql`
  {
    allContentfulImage(
      filter: {
        image: { file: { fileName: { eq: "Pho-Thai-Express-logo.png" } } }
      }
    ) {
      nodes {
        image {
          fixed(width: 200) {
            ...GatsbyContentfulFixed_withWebp
          }
          title
        }
      }
    }
  }
`

const Navbar = () => {
  const data = useStaticQuery(query)
  const {
    allContentfulImage: { nodes: image },
  } = data
  const { title } = image
  const fixed = image[0].image.fixed

  const { isSidebarOpen, links, extendLinks, toggleSidebar } = useContext(
    GatsbyContext
  )

  return (
    <nav className="navigation">
      <div className="navigation__center">
        <div className="navigation__header">
          <Link to="/">
            <Image
              fixed={fixed}
              alt={title}
              fadeIn={false}
              loading="eager"
              objectPosition="50% 50%"
            />
          </Link>
          {!isSidebarOpen && (
            <button
              className="navigation__toggle--btn"
              aria-label="navigation toggle button"
              onClick={toggleSidebar}
            >
              <FaAlignRight />
            </button>
          )}
        </div>

        <div className="navigation__wrap-links">
          <ul className="navigation__list">
            {links.map(link => {
              return (
                <li key={link.id}>
                  <Link to={link.url}>
                    {link.text}
                    {link.icon}
                  </Link>
                </li>
              )
            })}
          </ul>

          <ul className="navigation__list">
            {extendLinks.map(link => {
              return (
                <li key={link.id}>
                  <a href={link.href || link.url} target="blank">
                    {link.icon}
                  </a>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
