import React, { useState } from "react"

const SearchButtons = ({ menus, setMenus, setBackToAll }) => {
  const [index, setIndex] = useState(0)

  // Get only unique value
  const types = [
    "all",
    ...new Set(
      menus.map(menu => {
        return menu.type
      })
    ),
  ]

  // Set up onClick to trigger the menus
  const showMenu = (type, typeIndex) => {
    setIndex(typeIndex)
    // Set back to default value
    if (type === "all") {
      setBackToAll()
      // Set value that match type
    } else {
      const tempMenus = menus.filter(menu => menu.type === type)
      setMenus(tempMenus)
    }
  }

  return (
    <section id="search-buttons" className="u-margin-bottom-big">
      {types.map((type, typeIndex) => {
        return (
          <button
            key={typeIndex}
            className={index === typeIndex ? "active" : undefined}
            onClick={() => showMenu(type, typeIndex)}
          >
            {type}
          </button>
        )
      })}
    </section>
  )
}

export default SearchButtons
