import React from "react"
import { IoLogoFacebook } from "react-icons/io5"

export default [
  {
    id: 1,
    icon: <IoLogoFacebook className="icon" />,
    url: "https://www.facebook.com/phothaiexpress",
  },
]
