import React from "react"
import BackgroundImage from "gatsby-background-image"
import { graphql, useStaticQuery } from "gatsby"

const query = graphql`
  {
    heroBackgroundImage: allContentfulImage(
      filter: {
        image: {
          file: {
            fileName: {
              eq: "tom-yum-and-tom-kha-traditional-thai-soups-compress.jpg"
            }
          }
        }
      }
    ) {
      nodes {
        image {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyContentfulFluid_withWebp
          }
          title
          id
        }
      }
    }
  }
`

const Hero = () => {
  const data = useStaticQuery(query)
  const {
    heroBackgroundImage: { nodes: heroBackgroundImage },
  } = data
  const { heroBackgroundTitle } = heroBackgroundImage
  const fluid = heroBackgroundImage[0].image.fluid

  return (
    <section id="hero" className="u-margin-bottom-big">
      <BackgroundImage
        Tag="header"
        fluid={fluid}
        backgroundColor={`#eee`}
        className="bcg"
        preserveStackingContext={true}
        loading="lazy"
        fadeIn
        title={heroBackgroundTitle}
      >
        <div className="row">
          <div className="col-1-of-1">
            <article className="hero__info u-center-text">
              <h1 className="heading">Best Food, Good Taste</h1>
              <p className="heading-primary--main">
                {" "}
                Best Thai Food In Montpelier, Vermont
              </p>
            </article>
          </div>
        </div>
      </BackgroundImage>
    </section>
  )
}

export default Hero
