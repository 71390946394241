import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import FooterBottom from "./FooterBottom"
import FooterTop from "./FooterTop"
// import Background from "../Background"
import BackgroundImage from "gatsby-background-image"

const query = graphql`
  {
    allContentfulImage(
      filter: { image: { file: { fileName: { eq: "spices.jpg" } } } }
    ) {
      nodes {
        image {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyContentfulFluid_withWebp
          }
          title
          id
        }
      }
    }
  }
`

const Footer = () => {
  const data = useStaticQuery(query)
  const {
    allContentfulImage: { nodes: image },
  } = data
  const { title } = image
  const fluid = image[0].image.fluid

  return (
    <footer id="footer">
      <BackgroundImage
        Tag="div"
        fluid={fluid}
        backgroundColor={`#eee`}
        className="bcg__nofullscreen u-image-blur"
        preserveStackingContext={true}
        loading="lazy"
        fadeIn
        title={title}
      >
        <FooterTop />
        <FooterBottom />
      </BackgroundImage>
    </footer>
  )
}

export default Footer
