import React from "react"

const Map = ({ width, height }) => {
  return (
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2857.4492222044714!2d-72.5783496844825!3d44.259576979105184!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cb5a7f47a4ca079%3A0x8648dea556474b08!2sPho%20Thai%20Express!5e0!3m2!1sen!2sus!4v1614207151424!5m2!1sen!2sus"
      width={width}
      height={height}
      style={{ border: "0" }}
      title="Pho Thai Express Restaurant: Google Maps."
      allowFullScreen=""
      loading="lazy"
    ></iframe>
  )
}

export default Map
