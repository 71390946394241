import React from "react"

const FooterBottom = () => {
  return (
    <section className="footer-bottom">
      <div className="row">
        <div className="col-1-of-1">
          <article>
            <p className="footer-bottom__copyright">
              &copy; Pho Thai Express {new Date().getFullYear()} All rights
              reserved. -- Web design & Web development by{" "}
              <a
                href="https://www.clicksdesign.agency"
                target="blank"
                className="footer-bottom__link"
              >
                Clicks Design
              </a>
            </p>
          </article>
        </div>
      </div>
    </section>
  )
}

export default FooterBottom
