import React, { useContext } from "react"
import { MdClose } from "react-icons/md"
import { Link } from "gatsby"
import { GatsbyContext } from "../context/context"

const Sidebar = () => {
  const { links, isSidebarOpen, extendLinks, toggleSidebar } = useContext(
    GatsbyContext
  )

  return (
    <aside className="sidebar">
      <div className="sidebar__container">
        <button
          className="sidebar__close--btn"
          aria-label="sidebar close button"
          onClick={toggleSidebar}
        >
          <MdClose className="sidebar__close--btn--icon" />
        </button>
        <div className="wrap-links">
          <ul className={`${isSidebarOpen ? "sidebar__links" : ""}`}>
            {links.map(link => {
              const { id, text, icon, url } = link
              return (
                <li key={id}>
                  <Link to={url} href={link.href} onClick={toggleSidebar}>
                    {text}
                    {icon}
                  </Link>
                </li>
              )
            })}
          </ul>

          <ul className="sidebar__extendLinks">
            {extendLinks.map(link => {
              return (
                <li key={link.id}>
                  <a href={link.href || link.url} target="blank">
                    {link.icon}
                  </a>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </aside>
  )
}

export default Sidebar
