import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

import SearchButtons from "../components/SearchButtons"
import Title from "./Title"
// import menuLists from "../constants/menu"

const query = graphql`
  {
    allContentfulMenu {
      nodes {
        name
        prices
        type
        id
        info {
          info
          id
        }
      }
    }
  }
`

export const Menus = () => {
  const data = useStaticQuery(query)
  const {
    allContentfulMenu: { nodes: menuLists },
  } = data

  const [menus, setMenus] = useState(menuLists)
  // Set back to default value
  const setBackToAll = () => {
    setMenus(menuLists)
  }

  return (
    <section id="menu" className="menu u-margin-top-big u-margin-bottom-big">
      <div className="u-header-wrap u-margin-top-big u-margin-bottom-big">
        <div className="row u-page-header">
          <div className="col-1-of-1">
            <header>
              <Title
                title="menu"
                className="heading-title heading-title--grey"
              />
              <p className="heading-tertiary heading-tertiary--grey">
                Our authentic and delicious Thai food menu
              </p>
            </header>
          </div>
        </div>

        <div className="row">
          <div className="col-1-of-1">
            {/* In menus prop, it has to passes all the menuLists object to make the SearchButtons function to work */}
            <SearchButtons
              menus={menuLists}
              setMenus={setMenus}
              setBackToAll={setBackToAll}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-2-of-2fix">
            {menus &&
              menus.map(menu => {
                const { id, name, info, prices } = menu

                return (
                  <section className="menu__menu-lists">
                    <article key={id}>
                      <h3 className="menu__name heading-ordinary heading-ordinary--dark u-margin-top-small">
                        {name}
                      </h3>
                      <span className="menu__price">$ {prices.toFixed(2)}</span>
                      <div className="dotted-bg"></div>

                      <p className="paragraph paragraph--dark u-margin-top-small">
                        {info.info}
                      </p>
                    </article>
                  </section>
                )
              })}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Menus
