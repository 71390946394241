import React from "react"

export default [
  {
    id: 1,
    day: "Monday",
    hour: "11:30AM–7:45PM",
  },
  {
    id: 2,
    day: "Tuesday",
    hour: "11:30AM–7:45PM",
  },
  {
    id: 3,
    day: "Wednesday",
    hour: "11:30AM–7:45PM",
  },
  {
    id: 4,
    day: "Thursday",
    hour: "11:30AM–7:45PM",
  },
  {
    id: 5,
    day: "Friday",
    hour: "11:30AM–7:45PM",
  },
  {
    id: 6,
    day: "Saturday",
    hour: "12–8PM",
  },
  {
    id: 7,
    day: "Sunday",
    hour: "Closed",
  },
]
